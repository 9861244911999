import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from '../../shared/api/user-details.service';
import { NpsStore } from '../../../services/nps.store';

@Component({
    selector: 'nps-menu',
    templateUrl: './nps.menu.component.html'
})
export class NpsMenuComponent implements OnInit {

  items: any[] = [];

  allItems = [
    //{
    //  label: 'Home',
    //  icon: 'pi pi-fw pi-home',
    //  routerLink: '/'
    //},
    {
      label: 'Projects',
      icon: 'pi pi-fw pi-cog',
      permission: 'ProjectGetList,UserProjectMappingGetList',
      items: [{
        label: 'Projects Profiles',
        icon: 'pi pi-fw pi-cog',
        routerLink: '/nps/projects/list',
        permission: 'ProjectGetList'
      },
      {
        label: 'User Project Mappings',
        icon: 'pi pi-fw pi-users',
        routerLink: '/nps/projects/users',
        permission: 'UserProjectMappingGetList'
      }
      ]
    },
    {
      label: 'Networks',
      icon: 'pi pi-fw pi-sitemap',
      permission: 'OpNetworkRequestGetList,GpNetworkRequestCreate',
      items: [{
        label: 'Network Requests',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: '/nps/network-requests/list',
        permission: 'OpNetworkRequestGetList'
      },
      {
        label: 'New Network',
        icon: 'pi pi-fw pi-plus',
        routerLink: '/nps/network-requests/new',
        permission: 'GpNetworkRequestCreate'
      }
      ]
    },
    {
      label: 'Project Spokes',
      permission: 'GpProjectSpokesGetList',
      items: [
        // {
        //   //label: '[comming soon] Hubs Topology (categories)',
        //   label: '[comming soon] Hubs Topology',
        //   icon: 'pi pi-fw pi-sitemap',
        //   routerLink: '/nps/project-spokes/hubs/topology',
        //   permission: 'OpProjectSpokesTopology'
        // },
        {
          label: 'Primary Hubs',
          icon: 'pi pi-fw pi-server',
          routerLink: '/nps/project-spokes/hubs/primary-hubs/list',
          permission: 'OpPrimaryHubsGetList'
        },
        {
          label: 'Secondary Hubs',
          icon: 'pi pi-fw pi-server',
          routerLink: '/nps/project-spokes/hubs/secondary-hubs/list',
          permission: 'OpSecondaryHubsGetList'
        },
        {
          label: 'Project Spokes',
          icon: 'pi pi-fw pi-asterisk',
          routerLink: '/nps/project-spokes/list',
          permission: 'GpProjectSpokesGetList'
        },
        {
          label: 'Spoke Requests',
          icon: 'pi pi-fw pi-asterisk',
          routerLink: '/nps/project-spokes/requests/list',
          permission: 'OpSpokeRequestsGetList'
        },
        {
          label: 'Replicate Existing Spoke',
          icon: 'pi pi-fw pi-plus',
          routerLink: '/nps/project-spokes/replicate',
          permission: 'OpSpokeCreateReplication'
        },
      ]
    },
    {
      label: 'Firewall',
      permission: 'GpFirewallRequestCreate',
      items: [
        {
          label: 'Firewall Rules',
          icon: 'pi pi-fw pi-shield',
          routerLink: '/nps/firewall/rules/list',
          permission: 'OpFirewallRulesGetList'//,GpFirewallProjectRulesGetList
        },
        {
          label: 'Firewall Requests',
          icon: 'pi pi-fw pi-shield',
          routerLink: '/nps/firewall/requests/list',
          permission: 'OpFirewallRequestsGetList'//,GpFirewallRequestsGetList
        },
        {
          label: 'Create Firewall Request',
          icon: 'pi pi-fw pi-plus',
          routerLink: '/nps/firewall/requests/create',
          permission: 'GpFirewallRequestCreate'
        },
      ]
    }
  ];

  constructor(
    private userService: UserDetailsService,
    private store: NpsStore
  ) { }

  ngOnInit() {

    this.store.npsUser$
      .subscribe(user => {
        if (!user) {
          this.items = [];
        } else {
          //filters items based on permissions
          this.items = this.allItems
            .filter(i => !i.permission || this.userService.hasPermission(i.permission, user?.roles))
            .map(i => {
              i.items = i.items.filter(s => !s.permission || this.userService.hasPermission(s.permission, user?.roles));
              return i;
            });
        }
      });

  }
}
